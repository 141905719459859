<template>
<modal :modalId="`modaLInfoBB`" :modalTitle="'Banco do Brasil'" :load="false" :typeLg="`modal_wd`" @closeModal="closeModal">
  <tabs :item="list" />
</modal>
</template>

<script>
import modal from '@/components/modal/modalDefault'
import tabs from './tabs.vue'
export default {
  props: ['list'],
  components: {
    modal,
    tabs
  },
  methods: {
    closeModal () {}
  }
}
</script>

<style scoped>
</style>
