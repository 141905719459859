<template>
  <div>
    <div
      class="bg_black"
      :class="$route.path == '/metas' ? 'py-3 min_h' : 'h-100'"
    >
      <div class="row d-flex align-items flex-column" >
        <div></div>
        <div
          class="containerData"
          :class="
            $route.path == '/metas' ? 'col-md-8 mb-3' : 'col-md-6 px-1 my-3'
          "
        >
          <VueSvgGauge
            :start-angle="-176"
            :end-angle="184"
            :value="aroundMath(totalMetas)"
            :separator-step="25"
            :separatorThickness="0"
            :min="0"
            :max="100"
            :scale-interval="0"
            :gauge-color="[
              { offset: 0, color: '#EE4363' },
              { offset: 100, color: '#FF0000' }
            ]"
            :transition-duration="2500"
            baseColor="#093F69"
          >
            <div
              class="inner-text h-100 d-flex align-items-center justify-content-center"
              :style="`color:${scoreColor(aroundMath(totalMetas))}`"
            >
              <p class="mb-0" v-if="porceto === 'Percent'" key="porcento">
                <span>{{ aroundMathFixed(totalMetas) }}</span>
                <small>%</small>
              </p>
              <p
                class="mb-0"
                style="font-size: 23px;"
                v-else-if="porceto === 'money'"
                key="reais"
              >
                <span>{{ valueConvert(totalMetasReais) }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>

        <div
          class="d-flex align-items-center justify-content-around mt-3 containerMath"
        >
          <div class="containerCircle d-flex align-items-center">
            <div class="circle mr-2"></div>
            <span>{{ aroundMathFixed(totalMetas) }}</span>
            <small>%</small>
          </div>
          <div class="containerCircle d-flex align-items-center">
            <div class="circle whiteCircle mr-2"></div>
            <span>{{  totalMetas > 100 ? 0 : roundFn( 100 - totalMetas, 1) }}</span>
            <small>%</small>
          </div>
        </div>

        <div
          class="col-md-10 mx-auto text-center storeValueAll"
          :class="$route.path == '/' ? 'mb-3' : ''"
        >
          <p class="mb-0">
            Sua loja atingiu
            <strong>{{ aroundMath(totalMetas) }}%</strong> da meta total do mês.
          </p>
        </div>
        <div
          v-if="ticketAverage && ticketAverageLastMonth"
          class="col-md-10 mx-auto"
        >
          <p class="ticket text-center py-3 mb-0">
            R$
            <strong>{{ ticketAverage | currency }}</strong> Ticket Médio Mês
            Atual
          </p>
          <p class="ticket text-center pb-3 mb-0">
            R$
            <strong>{{ ticketAverageLastMonth | currency }}</strong> Ticket
            Médio Mês Anterior
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ticketAverage", "ticketAverageLastMonth", "porceto"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    totalMetas() {
      return this.$store.state.login.tenantScore;
    },
     user() {
      return this.$store.state.login;
    },
    totalMetasReais() {
      return this.$store.state.login.tenantOverallSold;
    }
  }
};
</script>

<style scoped>
.min_h {
  min-height: 220px;
}

p {
  color: #ffff;
  font-size: 10px;
  font-weight: 500;
}

.bg_black {
  min-height: 230px;
  background: #072e4d;
  border: none;
  border-radius: 12px;
  width: 268px;
}

.inner-text p {
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  text-align: center;
}

small {
  font-size: 20px;
}

.ticket strong {
  color: #e0a638;
  font-size: 14px;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: linear-gradient(270deg, #df1f32 0%, #ee4363 100%);
}

.whiteCircle {
  background: #093f69 !important;
}

.containerCircle span,
.containerCircle small {
  font-weight: 700;
  font-size: 12px;
  color: #98c4e6 !important;
}

.containerMath {
  border-bottom: 1px solid #2474b2;
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 8px;
}

.containerData {
  margin: 20px auto 10px !important;
}

.storeValueAll p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 10px 0 0;
}

</style>
