<template>
<div>
  <div class="container m-0">
    <div class="main pt-2">
      <div>
        <ul class="nav nav-pills mb-3 tabNav" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Dados do cliente</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Dados do Serviço</a>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.name">
              <label class="m-0" for="name">Nome: </label>
              <p class="m-0 px-2">{{ item.clientFk.name }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk != null && item.clientFk.cpf">
              <label class="m-0" for="">CPF:</label>
              <p class="m-0  px-2">{{ item.clientFk.cpf }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.cnpj">
              <label class="m-0" for="">CNPJ:</label>
              <p class="m-0  px-2">{{ item.clientFk.cnpj }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.cellPhone">
              <label class="m-0" for="">Telefone:</label>
              <p class="m-0  px-2">{{ item.clientFk.cellPhone }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.city">
              <label class="m-0" for="">Cidade:</label>
              <p class="m-0  px-2">{{ item.clientFk.city }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.stateCity">
              <label class="m-0" for="">Estado:</label>
              <p class="m-0  px-2">{{ item.clientFk.stateCity }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.address">
              <label class="m-0" for="">Endereço:</label>
              <p class="m-0  px-2">{{ item.clientFk.address }}</p>
            </div>
            <div class="d-flex align-items-center py-2" v-if="item.clientFk && item.clientFk.email">
              <label class="m-0" for="">email:</label>
              <p class="m-0  px-2">{{ item.clientFk.email }}</p>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="d-flex align-items-center py-2" v-for="items in item.serviceFields" :key="items">
              <label class="m-0" for="">{{ items.split(':')[0] }}:</label>
              <p class="m-0  px-2">{{ items.split(':')[1] }}</p>
            </div>
            <div class="borderContainer" v-if="item && item.ordersServices && item.ordersServices[0].orderServiceFiles.length > 0">
              <h3>Arquivos</h3>

              <div class="d-flex justify-content-between align-items-center borderItens" v-for="(item, index) in item.ordersServices[0].orderServiceFiles" :key="index">
                <!-- {{ item.id }} -->
                <p class="m-0">{{ item.hashName.split('_')[1] }}</p>
                <button class="btnEdit m-2" @click="dowloadFile(item.fullPath)">
                  <img src="@/assets/image/iconesUpload/download.svg" alt="download" />
                </button>
              </div>
            </div>
            <div v-else class="borderContainer mt-5 d-flex align-content-center justify-content-center">
              <h3>Não existe arquivos para esse serviço</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapActions
} from 'vuex'
export default {
  props: ['item'],
  methods: {
    ...mapActions(['downloadService']),
    async dowloadFile (file) {
      try {
        const {
          data
        } = await this.downloadService(file)

        let a = document.createElement('a')
        a.href = `data:${data.result.mimeType};base64,${data.result.image64}`
        a.download = data.result.nameFile.split('_')[1]
        a.click()
        this.toast('Iniciando Download', this.$toast)
      } catch (error) {
        this.toast('Erro', this.$toast.error)
      }
    }
  }
}
</script>

<style scoped>
.container {
  background: #fff;
  background: #ffffff;
  border-radius: 12px;
}

.container h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2474b2;
  padding: 10px 0 10px 0;
  border-bottom: 1.04934px solid #98c4e6;
}

label {
  font-size: 1rem;
  color: #9b9797e3;
}

p {
  font-size: 1rem;
  color: #4e8fc1;
  font-style: normal;
  font-weight: 600;
}

.btnEdit {
  background: none;
  border: none;
  height: 30px;
  /* width: 40px; */
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  outline: none;
  color: #fff;
  border: 1px solid #2474b2;
  background: rgba(43, 145, 223, 0.788);
}

.nav-link {
  color: #98c4e6;
  font-weight: 700;
  font-size: 14px;
}

.nav-link.active {
  color: #3f88c1;
  background-color: none;
  border-bottom: 6px solid;
  border-radius: 0;
  background: none;
}

.tabNav {
  border-bottom: 2px solid #98c4e6;
}

.borderItens {
  border-bottom: 1px solid #98c4e6;
}

.borderContainer {
  margin: 40px 0 0;
  border-top: 1px solid #98c4e6;
  border-bottom: 1px solid #98c4e6;
  padding: 0 10px 0 0;
}

.borderContainer h3 {
  color: #2474b2;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
}
</style>
