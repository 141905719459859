<template>
  <div class="col-xl-12 col-md-10">
    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 250px"
      v-if="load"
    >
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
    </div>
    <div class="row" v-show="!load">
      <div class="col-md-12 mt-2 mb-1">
        <transition name="fade">
          <div v-show="!load" class="containerButton">
            <p class="titulo_dash mb-0 mr-5 classText">
              Suas Metas para este Mês
            </p>
            <ButtonToggle
              :activo="activoCurrent"
              @toggleConvert="toggleBtn"
              v-if="money"
            />
          </div>
        </transition>
        <hr class="hrTop" />
      </div>
    </div>
    <div class="container ml-0" v-show="!load">
      <div class="row flex-nowrap">
        <transition name="vertical">
          <total
            class="col-auto mb-3 px-2"
            v-show="!load"
            :porceto="activoCurrent"
          />
        </transition>
        <hr class="p-0 hr" />
        <transition name="vertical">
          <totalUnidade
            class="col-auto mb-3 px-2"
            v-show="!load"
            :porceto="activoCurrent"
          />
        </transition>
        <transition name="fade">
          <Pedidos
            class="col p-0"
            :items="pendingOrders"
            :length="pendingOrders.length"
            v-show="!load"
            @openModal="openModal"
          />
        </transition>
      </div>
    </div>
    <div v-if="images.length > 0 && this.sizeWindow > 767" class="mt-5 col-12">
      <VueSlickCarousel :arrows="true" v-bind="settings">
        <div
          v-for="item in images"
          :key="item.id"
          class="d-flex justify-content-center"
        >
          <a :href="item.link" target="_blank">
            <img
                class="mx-auto"
                :src="getImg(item.image)"
                :alt="`${item.image}`"
              />
          </a>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="col-md-12 titulo_dash">
      <transition name="fade">
        <p class="my-3 titulo_dash classText service">
          Serviços mais Vendidos
        </p>
      </transition>

      <transition-group
        name="list-complete"
        tag="div"
        class="row"
        v-if="showComponent"
      >
        <mais_pedidos
          v-for="(item, index) in requestedServices"
          :key="index + 1"
          :item="item"
          class="list-complete-item"
        />
      </transition-group>
    </div>
    <div class="col-md-12">
      <transition name="fade">
        <p class="my-3 titulo_dash" v-if="expressServices != ''">
          Serviços expressos
        </p>
      </transition>
      <transition-group name="list-complete" tag="div" class="row">
        <mais_pedidos
          v-for="(item, index) in expressServices"
          :key="index + 1"
          :item="item"
          class="list-complete-item"
        />
      </transition-group>
    </div>
    <p class="my-3 titulo_dash classText service pl-2">
      Ticket Médio
    </p>
    <div class="col-auto mt-2 pl-1 ">
      <Ticket :ticketAll="ticketAll" :ticketStore="ticketStore" />
    </div>
  </div>
</template>

<script>
import metrica from '@/components/metas/vendedor/Metas_mensais'
import Pedidos from '@/components/metas/vendedor/_pedidos_abertos.vue'
import total from '@/components/metas/vendedor/_Total_metas'
import totalUnidade from '@/components/metas/vendedor/_Total_metas_unidade'
import mais_pedidos from './_dashBoards_item/_Servicos_mais_cotados'
import { HalfCircleSpinner } from 'epic-spinners'
import ButtonToggle from '../metas/buttons/button_toggle.vue'
import Ticket from '@/components/metas/vendedor/_ticket.vue'
import HTTP from '@/api_system'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    metrica,
    total,
    totalUnidade,
    mais_pedidos,
    ButtonToggle,
    HalfCircleSpinner,
    Pedidos,
    Ticket,
    VueSlickCarousel
  },
  data () {
    return {
      categoryScore: [],
      expressServices: [],
      requestedServices: 3,
      showComponent: false,
      load: true,
      loaderComponent: false,
      activoCurrent: 'Percent',
      scoresValues: 'Percent',
      sizeWindow: 0,
      ticketAll: false,
      money: false,
      pendingOrders: [],
      ticketStore: {},
      settings: {
        autoplay: true
      },
      images: []
    }
  },
  mounted () {
    this.sizeWindow = $(window).width();
    this.getList();
    this.getAll()
    this.showComponent = true
  },
  methods: {
    openModal (values) {
      console.log(values)
    },
    getSalesman () {
      HTTP.get('services/app/Score/GetScoreByServiceBySalesman', {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(({ data }) => {
          this.load = false
          this.loaderComponent = false
          this.categoryScore = data.result.listScoreByCategory
          this.ticketStore = data.result
        })
        .catch(error => {
          this.load = false
          this.$notify({
            group: 'erros',
            type: 'error',
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          })
        })
    },
    getAll () {
      this.getSalesman()
      this.$store
        .dispatch('GetDashboardSallesman')
        .then(resolve => {
          this.expressServices = resolve.expressServices
          this.requestedServices = resolve.requestedServices
          const {
            canSeeAverageDailyTicket,
            canSeeGoalInMoney,
            pendingOrders
          } = resolve

          this.ticketAll = canSeeAverageDailyTicket
          this.money = canSeeGoalInMoney
          const itens = pendingOrders.filter((item, index) => index < 10)
          this.pendingOrders = itens
        })
        .catch(reject => {
          this.$notify({
            group: 'erros',
            type: 'error',
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          })
        })
    },
    toggleBtn (e) {
      this.activoCurrent = e
    },
    getList () {
      let user = this.$store.getters.getUser;
      HTTP.get(`services/app/Notice/GetDashBoardNotices`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then((response) => {
          let res = response.data.result
          let imgs = [];
          res.forEach((item) => {
            if ((item.profile == user.profile || item.profile == 0) && item.type == 1) {
              imgs.push(item)
            }
          })
          this.images = imgs;
        })
        .catch((err) => console.log(err))
    },
  }
}
</script>

<style scoped>
.metas_p {
  color: #ffffff;
}

.a_hrf {
  color: #ffffff;
}

.list-complete-item {
  transition: all 1s !important;
}

.vertical-enter-active {
  transition: all 1s !important;
}

.containerButton {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.classText {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
}

.service {
  border-bottom: 1px solid #3f88c1;
  padding-bottom: 10px;
}

.hrTop {
  height: 2px;
  background: #3f88c1;
}

.hr {
  height: 300px;
  background: #3f88c1;
  margin: 0;
  width: 2px;
  margin: 0 8px;
}

.containeRequests {
  height: 100%;
  background: #fff;
  border-radius: 12px;
  max-height: 300px;
  margin-bottom: 8px;
  overflow: auto;
}

.containeRequests .circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f38235;
}

.containeRequests h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #f38235;
}

.containeRequests hr {
  width: 92%;
  border: 1px solid #e9e9eb;
}

.containerBtn {
  width: 100%;
  display: flex;
}

.watchAll {
  background: none;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #98c4e6;
  margin: 0 0 0 auto;
  cursor: pointer;
  z-index: 333;
  outline: none;
}

.containerBanner {
  max-width: 960px;
  max-height: 105px;
  margin: 0 auto;
  margin-top: 20px;
}

.image-arrow {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
